import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from './layout'
import { CardsSection, Seo, AreasSection } from '../components'
import useCourses from '../hooks/useCourses'

 const TrainingType = ({ data }) => {
  const trainingType = data.allStrapiTrainingType.nodes[0]
  const {slug, title} = trainingType

  const dataCourses = useCourses()
  const fjsCourses = dataCourses?.allStrapiFjsCourses?.nodes

  const ttCourses = fjsCourses.filter((course) =>
    course.training_type.slug === slug
  )

  const newCourses = ttCourses.filter(
    (course) => course.dateStart >= new Date().toISOString()
  )
 
  const expiredCourses = ttCourses.filter(
    (course) => course.dateStart < new Date().toISOString()
  ).reverse()

  return (
    <Layout>
      <Seo title={title} />
      <AreasSection />
      {/* <SimpleBanner image={banner} /> */}
      <div className="primary-background">
        <div className="container py-5">
        <CardsSection courses={newCourses} areas={true} id="cursos"/>
        {expiredCourses.length > 0 &&         
        <CardsSection
            courses={expiredCourses}
            related={true}
            title={'Cursos comenzados'}
            id={'cursos-comenzados'}
        />}

        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query($slug: String!) {
    allStrapiTrainingType (filter: { slug: { eq: $slug } }) {
    nodes {
      title
      slug
      strapiId
    }
  }
}
`
export default TrainingType